import React from 'react'
import {styled} from "@mui/material/styles";

export const BackgroundText = styled('span')(({ theme }) => ({
    backgroundColor: theme.palette.primary.main,
    padding: '0.5rem',
    textAlign: 'left',
    boxDecorationBreak: 'clone',
    webkitBoxDecorationBreak: 'clone'
}));
