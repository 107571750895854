import * as React from "react"
import {
  Alert,
  Box, Divider,
  Grid, List, ListItem, ListItemIcon, ListItemText, Stack,
  Typography,
} from "@mui/material";
import Container from "@mui/material/Container";
import {StaticImage} from "gatsby-plugin-image";
import {Button, Link} from "gatsby-material-ui-components";

import {ReactNode} from "react";
import Oppervlakte from "../components/oppervlakte";
// @ts-ignore
import Bullit from '../images/bullit.svg';
import {BackgroundText} from "../components/typography";
import {sectionSpacing, TEXT_PADDING} from "../theme";
import {Bouwnummer} from "../graphqlTypes";
import {graphql} from "gatsby";
import Bouwnummers from "../components/bouwnummers";
import { red } from "@mui/material/colors";


interface WoningtypeProps {
  title: string
  subtitle: string
  woningen: Bouwnummer[]
  items: ReactNode[]
  image: ReactNode
}
const Woningtype = ({title, subtitle, woningen, items, image}: WoningtypeProps) => {
  return (
    <Stack spacing={4} alignItems={'start'}>
      <Box>
        <Typography
          variant={'h2'}
        >
          {title}
        </Typography>
        <Typography
          variant={'h5'}
          color={'primary'}
          fontWeight={'bold'}
          textTransform={'uppercase'}
        >
          {subtitle}
        </Typography>
      </Box>
      {image}
      <Stack
        direction={'row'}
        minHeight={136}
        width={'100%'}
        borderTop={1}
        borderBottom={1}
        paddingY={2}
        borderColor={'primary.main'}
      >
        <Typography marginRight={2}>
          Bouwnr.
        </Typography>
        <Box>
          <Bouwnummers bouwnummers={woningen}/>
        </Box>
      </Stack>
      <List
        dense={true}
        sx={{
          flexGrow: 100
        }}
      >
        {items.map((item, index) =>
          <ListItem key={index}>
            <ListItemIcon>
              <StaticImage src={'../images/bullit.svg'} alt={''}/>
            </ListItemIcon>
            <ListItemText
              disableTypography
              primary={<Typography fontFamily={'Finlandica'} fontWeight={'bold'}>{item}</Typography>}
            />
          </ListItem>
        )}
      </List>
      <Button
        variant={'contained'}
        color={'primary'}
        size={'large'}
        to={`/woningen`}
        sx={{
          color: 'secondary.main'
        }}
      >
        Bekijk woning
      </Button>
    </Stack>
  )
}

interface Props {
  data: PageQueryData
}
export default ({data}: Props) => {
  return (
    <>
      <Box
        component={'header'}
        display={'grid'}
      >
        <StaticImage
          src="../images/impressies/2112_CAM5_Ontwikkelveld_zeewolde.jpg"
          alt="header"
          layout={'fullWidth'}
          style={{
            gridArea: "1/1",
            // You can set a maximum height for the image, if you wish.
            maxHeight: 800,
          }}
        />
        <Box
          display={'flex'}
          flexDirection={'column'}
          sx={{
            // By using the same grid area for both, they are stacked on top of each other
            gridArea: {md:"1/1"},
            position: "relative",
            // This centers the other elements inside the hero component
            placeItems: "end center",
            display: "grid",
          }}
        >
          <Container
            sx={{
              marginY: sectionSpacing
            }}
            maxWidth={'lg'}
          >
            <Grid container alignItems={'end'} spacing={4}>
              <Grid item xs={12} md={8}>
                <Stack alignItems={'start'} spacing={2}>
                  <Typography
                    color={red[500]}
                    variant={'h6'}
                    fontWeight={'bold'}
                  >
                    <BackgroundText>
                      Alle woningen zijn verkocht
                    </BackgroundText>
                  </Typography>
                  <Typography
                    color={'secondary.main'}
                    variant={'h6'}
                    fontWeight={'bold'}
                  >
                    <BackgroundText>
                      SCHELPENBUURT, ZEEWOLDE
                    </BackgroundText>
                  </Typography>
                  <Typography
                    color={'common.white'}
                    variant={'h3'}
                    sx={{
                      textShadow: '0px 0px 15px #00000091'
                    }}
                  >
                    3 vrijstaande woningen en<br />
                    15 appartementen
                  </Typography>
                  <Button
                    to={'/woningen'}
                    variant={'contained'}
                    size={'large'}
                    color={'secondary'}
                    sx={{
                      color: 'primary.main'
                    }}
                  >
                    Bekijk woningen
                  </Button>
                </Stack>
              </Grid>
            </Grid>
          </Container>
        </Box>
      </Box>
      <Container maxWidth={'lg'}>
        <Box
          component={'section'}
          marginY={sectionSpacing}
        >
          <Grid
            container
            spacing={4}
          >
            <Grid
              item
              xs={12}
              md={6}
            >
              <Typography
                variant={'h4'}
                color={'primary'}
              >
                Groen en waterrijk
              </Typography>
              <Typography variant={'body2'} paragraph>
                De Schelpenbuurt is een nieuwe, groene én waterrijke buurt in Zeewolde. Het wonen op en rond een haveneiland biedt naast sereniteit ook de nodige levendigheid. Het is een ontspannen komen en gaan van plezierboten.
              </Typography>
              <Box
                borderColor={'primary.main'}
                borderLeft={'2px solid'}
                color={'primary.main'}
                fontStyle={'italic'}
                fontSize={23}
              >
                <blockquote>
                  De Wachters vormen de toegangspoort naar de nieuwe Schelpenbuurt van Zeewolde.
                </blockquote>
              </Box>
              <Typography variant={'body2'} paragraph>
                Wie vanaf het Wolderwijd de sluis doorvaart wordt verwelkomd door De Wachters. De Wachters vormen de toegangspoort naar de nieuwe Schelpenbuurt van Zeewolde. De combinatie van De Wachters bestaat uit 3 vrijstaande woningen en 15 appartementen.
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
            >
              <StaticImage
                src={'../images/sfeer/GettyImages-514270087@2x.jpg'}
                alt={'water'}
              />
            </Grid>
          </Grid>
        </Box>
        <Divider
          sx={{
            borderColor: `primary.main`,
            borderWidth: 1,
            marginY: 4
          }}
        />
        <Box
          component={'section'}
          marginY={sectionSpacing}
        >
          <Grid
            container
            spacing={10}
          >
            <Grid
              item
              xs={12}
              md={6}
              display={'flex'}
            >
              <Woningtype
                title={'De Brugwachter'}
                subtitle={'3 Vrijstaande woningen'}
                woningen={data.vrijstaand.nodes || []}
                image={<StaticImage src={'../images/impressies/2112_CAM3_Ontwikkelveld_zeewolde.jpg'} alt={'impressie'} />}
                items={[
                  <>Circa <Oppervlakte oppervlakte={'150'} /> woonoppervlakte</>,
                  <>Circa <Oppervlakte oppervlakte={'364 tot 476'} /> perceeloppervlakte</>,
                  <>Eigen ligplaats aan open vaarwater</>,
                  <>Duurzame woning, aangesloten op collectief warmtenet</>,
                  <>2 parkeerplaatsen op eigen terrein</>
                ]}
              />
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              display={'flex'}
            >
              <Woningtype
                title={'De Sluiswachter'}
                subtitle={'15 Appartementen'}
                woningen={data.appartementen.nodes || []}
                image={<StaticImage src={'../images/impressies/2112_CAM8_Ontwikkelveld_zeewolde.jpg'} alt={'impressie'} />}
                items={[
                  <>Circa <Oppervlakte oppervlakte={'42 tot 83'} /> woonoppervlakte</>,
                  <>Eigen parkeerplaats</>,
                  <>Buitenruimte op het zuidwesten</>,
                  <>Gemeenschappelijk terras en aanlegplaats aan het water</>,
                  <>Duurzaam appartement, aangesloten op collectief warmtenet</>
                ]}
              />
            </Grid>
          </Grid>
        </Box>
      </Container>
    </>
  )
}

interface PageQueryData {
  vrijstaand: {
    nodes: Bouwnummer[]
  }
  appartementen: {
    nodes: Bouwnummer[]
  }
}

export const query = graphql`
    query {
        vrijstaand: allBouwnummer(filter: {bouwtype: {diversen: {objectcode: {eq: "O100116"}}}}) {
            nodes {
                ...BouwnummerPrijslijst
            }
        }
        appartementen: allBouwnummer(filter: {bouwtype: {diversen: {objectcode: {ne: "O100116"}}}}) {
            nodes {
                ...BouwnummerPrijslijst
            }
        }
    } 
`
