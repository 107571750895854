import * as React from "react"
import {Bouwnummer} from "../graphqlTypes";
import {Button} from "@mui/material";
import {statusMap} from "../utils/utils";
import {useState} from "react";
import BouwnummerModal from "./bouwnummer/modal";

interface Props {
    bouwnummers: Bouwnummer[]
}
const Bouwnummers = ({bouwnummers}: Props) => {

    const [selectedBouwnummer, setSelectedBouwnummer] = useState<Bouwnummer | null>()

    return (
        <>
            {bouwnummers.map(bouwnummer =>
                <Button
                    key={bouwnummer.id}
                    variant={'contained'}
                    color={statusMap[bouwnummer.financieel?.overdracht?.status].variant}
                    size={'small'}
                    onClick={() => setSelectedBouwnummer(bouwnummer)}
                    sx={{
                        color: 'common.white',
                        marginRight: 1,
                        marginBottom: 1,
                        padding: 0
                    }}
                >
                    {bouwnummer.diversen.diversen.bouwnummer}
                </Button>
            )}
            <BouwnummerModal
                bouwnummer={selectedBouwnummer}
                handleClose={() => setSelectedBouwnummer(null)}
            />
        </>
    )
}

export default Bouwnummers
